// export const baseURL = 'https://api.iddc-data-portal.co.uk';
export const baseURL = 'https://api-staging.iddc-data-portal.co.uk';
// export const baseURL = 'http://127.0.0.1:8000';

export const resObj = {
  axiosError: false,
  errMsg: '',
  errHeading: '',
  successMsg: '',
  showAlert: false,
  loading: false,
};

export const paginationVal = {
  pageCount: 0,
  pageNumber: 1,
  searchTerm: '',
};