import { useContext } from "react";
import PropTypes from 'prop-types';

import NotificationContext from "../../context/NotificationContext";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../layout/SearchBar";

import BarcodeIcon from "../layout/BarcodeIcon";

const FormulationsHeader = ({ 
  title, handleClick, hasCreatePermission, search, pageData, 
  setIsBulkDelete, setDeleteModalOpen, handleBarcodeClick
}) => {
  const { notificationCount, handleNotificationClick } = useContext(NotificationContext);

  const handleDeleteClick = () => {
    setIsBulkDelete(true);
    setDeleteModalOpen(true);
  };

  return(
    <Grid container spacing={1} style={{ marginTop: 2 }}>
      <Grid 
        item 
        xs={12} 
        md={hasCreatePermission ? 2.5 : 5.25} 
        lg={hasCreatePermission ? 3.25 : 5.75} 
        xl={hasCreatePermission ? 4.5 : 6.5}
      >
        <Typography 
          component="h4" 
          variant="h4" 
          color={'text.secondary'} 
        >
          <strong>{title}</strong>
        </Typography>
      </Grid>

      <Grid item xs={12} md={5} lg={5} xl={4.5}>
        <SearchBar search={search} pageData={pageData} />
      </Grid>

      {hasCreatePermission &&
        <Grid item xs={6} md={1.5} lg={1.25} xl={1}>
          <Button
            variant="contained" 
            color="success"
            onClick={handleClick}
            style={{
              width: '100%'
            }}
          >
            Comment
          </Button>
        </Grid>
      }

      {hasCreatePermission &&
        <Grid item xs={6} md={1.5} lg={1.25} xl={1}>
          <Button
            variant="contained" 
            color="error"
            onClick={handleDeleteClick}
            style={{
              width: '100%'
            }}
          >
            Delete
          </Button>
        </Grid>
      }

      <Grid item xs={6} md={0.75} lg={0.5} xl={0.5}>
        <Box 
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: -1,
          }}
        >
          <Tooltip title="Plate Barcodes" arrow>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleBarcodeClick}
            >
              <BarcodeIcon style={{ fontSize: 35 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>

      <Grid item xs={6} md={0.75} lg={0.75} xl={0.5}>
        <Box 
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: -1,
          }}
        >
          <Tooltip title="View notifications" arrow>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleNotificationClick}
            >
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon 
                  color="action" 
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

FormulationsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  hasCreatePermission: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
  setIsBulkDelete: PropTypes.func.isRequired,
  setDeleteModalOpen: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
  handleBarcodeClick: PropTypes.func.isRequired,
};

export default FormulationsHeader;